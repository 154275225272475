module.exports = {
    // document title
    Title: "东云管理中心",

    tokenExpires: 2592000000, // 30 days

    API: {
        __cms: "https://cms.easticloud.com/",
    },
};
