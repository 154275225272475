<template>
    <router-view />
</template>

<script>
import { mapMutations } from "vuex";
import { Local } from "@/utils/storage";
export default {
    name: "App",
    data: function () {
        return {};
    },
    created() {
        this.initSidebar();
    },
    methods: {
        ...mapMutations({
            setSidebarStatus: "app/SET_SIDEBAR_STATUS",
        }),
        initSidebar() {
            const sidebar = Local.get("sidebarStatus") || 1;
            if (window.innerWidth < 768) {
                this.setSidebarStatus(false);
            } else {
                this.setSidebarStatus(sidebar);
            }
        },
    },
};
</script>

<style lang="less">
@import "@/assets/css/app.less";
</style>
