import Layout from "@/layouts/default.vue";
const contactRouter = {
    path: "/contact",
    name: "contact",
    component: Layout,
    redirect: "/contact/index",
    meta: {
        title: "反馈管理",
        icon: "contact",
    },
    children: [
        {
            path: "index",
            name: "contact-index",
            component: () => import("@/views/contact/message"),
            meta: {
                title: "留言管理",
                icon: "message",
            },
        },
        {
            path: "detail/:id",
            name: "contact-detail",
            component: () => import("@/views/contact/detail"),
            hidden: true,
            meta: {
                title: "留言详情",
            },
        },
    ],
};

export default contactRouter;
