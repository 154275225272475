import Layout from "@/layouts/default.vue";

const billRouter = {
    path: "/bill",
    name: "bill",
    component: Layout,
    redirect: "/bill/overview",
    meta: {
        title: "费用中心",
        icon: "bill",
    },
    children: [
        {
            path: "index",
            name: "bill-overview",
            component: () => import("@/views/bill/overview"),
            meta: {
                title: "账单概览",
                icon: "bill-overview",
            },
        },
        {
            path: "detail",
            name: "bill-detail",
            component: () => import("@/views/bill/detail"),
            meta: {
                title: "账单详情",
                icon: "bill-detail",
            },
        },
    ],
};

export default billRouter;
